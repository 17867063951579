module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-V279PSFMJ3"],"pluginConfig":{"head":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://pcc-batiment.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PCC Bâtiment","short_name":"PCC Bâtiment","description":"Pour vos projets et travaux de rénovation en plâtrerie-peinture, isolation, PCC Bâtiment certifiée Qualibat RGE à Roanne, est à votre écoute.","lang":"fr","display":"standalone","start_url":"/","background_color":"#ffffff","theme_color":"#002da0","icon":"src/images/logos/icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"965ba0861f1b6c78df23815a1dbd410f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/runner/work/pcc-batiment/pcc-batiment/node_modules/gatsby-remark-external-links","id":"ba0435ef-9b07-5c28-a01a-374dc78a0d64","name":"gatsby-remark-external-links","version":"0.0.4","pluginOptions":{"plugins":[],"target":"_blank","rel":"noopener noreferrer"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/pcc-batiment/pcc-batiment","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
